<template>
  <div id="accountBank">
    <div v-if="loaded">
      <!-- BANK TABLE -->
      <b-card no-body class=" mt-1" border-variant="warning" >
        <b-card-header class="p-1" >
          <b-card-title class="font-medium-2 w-100">
            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <span class="align-middle ml-50" style="white-space: nowrap">{{ msg('travelagency') }}</span>
                <span class="align-middle ml-50" style="white-space: nowrap;font-weight: normal;font-size: 13px"> (Klicken um die Dokumente zu sehen)</span>
              </b-col>
              <b-col v-if="!disableAddBank" cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <button id="addBank" size="sm" class="btn btn-primary">
                    <feather-icon icon="UserPlusIcon" size="12"/>
                    {{ msg('Add agency') }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card-title>
        </b-card-header>
        <b-table
            ref="bankDetails-table"
            striped
            hover
            responsive
            v-bind:items="currentBankList"
            v-bind:fields="fields"
            :busy="!loaded"
            @row-clicked="onRowClicked">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(monthlyPayment)="data">
            {{ convertToEURO(data.value) }}
          </template>
          <template #cell(mortgage)="data">
            {{ convertToEURO(data.value) }}
          </template>
          <template #cell(interest)="data">
            {{ data.value }}%
          </template>
          <template #cell(creditStart)="data">
            {{ $moment(data.value, 'YYYY-MM-DD').format("DD/MM/YYYY") }}
          </template>
          <template #cell(creditEnd)="data">
            {{ $moment(data.value, 'YYYY-MM-DD').format("DD/MM/YYYY") }}
          </template>

          <template v-if="!disableAddBank" #cell(id)="data">

            <b-button style="width: 95px" size="sm" @click="info(data.item, data.index, $event.target)"
                      class="btn btn-warning d-block mt-1 mb-1">
              {{ msg('delete') }}
            </b-button>

          </template>
<!--
          <template v-for="(field,index) in editableFields" v-slot:[`cell(${field.key})`]="{ item }">
            <b-input number v-bind:key="index" v-model="item[field.key]" @change="onValueUpdated"/>
          </template>
-->

          <template #row-details="{ item }">
            <div v-if="item!=null && !disableAddBank" class="mb-1" style="text-align: right">
              <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                        @click="()=>uploadFile =!uploadFile">
                <i class="fas fa-upload"></i> {{ msg("Upload file") }}
              </b-button>
            </div>
            <div>
              <b-col>
                <b-form-group :label="msg('Enter here the email address and choose the documents down below (make sure you havent selected more than 25MB)')" label-for="email">
                  <b-input-group>
                    <b-form-input id="email" v-model="emailAddress"/>
                    <b-input-group-append>
                      <b-button :disabled="emailAddress==null || emailAddress.length===0|| selectedBankFileIds.length<1" variant="outline-primary" v-b-tooltip :title="msg('Send Email')"
                                @click="sendAllFilesByMail">
                        <i class="fa fa-mail-bulk mr-1"></i> EMAIL
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </div>
            <div class="files-data">
              <div class="upload-backdrop" v-show="uploadFile === true">
                <vue-dropzone id="contract-upload" class="h-100" :options="dropzoneOptions" :useCustomSlot=true
                              :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                              @vdropzone-success="uploadSuccess">
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                    <div class="subtitle">...or click to select a file from your computer</div>
                  </div>
                </vue-dropzone>
              </div>
              <template v-if="currentBankFileList!=null&&currentBankFileList.length>0">
                <b-table-simple bordered outlined stacked striped>
                  <b-tr style="background-color: rgba(229,16,48,0.77)!important;">
                    <b-th class="text-center" style="width: 10%">{{ msg('Select') }}</b-th>
                    <b-th class="text-center" style="width: 40%">{{ msg('File') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ msg('Tag') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ msg('Note') }}</b-th>
                    <b-th class="text-center" style="width: 15%">{{ msg('Operations') }}</b-th>
                  </b-tr>
                </b-table-simple>
                <perfect-scrollbar class="position-relative " style="height: auto">
                  <b-table-simple outlined>
                    <b-tr v-for="(file,index) in (currentBankFileList || [])" v-bind:key="file.id"
                          style="white-space: nowrap;text-overflow: ellipsis">
                      <b-td class="text-center" style="width: 10%">
                        <b-form-checkbox
                            v-model="selectedBankFileIds" :value="file.id"
                        />
                      </b-td>
                      <b-td style="width: 40%">
                        <i v-bind:class="iconByExt(file.filename)" class="mx-1"
                           style="font-size: 20px"></i>{{ file.filename }} <span v-if="file.size!=null" style="color: red">{{getFileSize(file.size)}}</span>
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <v-select :options="fileTags" :reduce="(tag) => tag.value" label="title" v-model="file.tag"
                                  append-to-body @input="()=>submitDocumentFile(index)">
                          <template v-slot:option="option">
                            <i class="d-inline-block text-left" style="width: 30px" :class="option.icon"></i>
                            <span>{{ option.title }}</span>
                          </template>
                        </v-select>
                      </b-td>

                      <b-td class="text-center" style="width: 20%">
                        <b-form-textarea v-model="file.notes" @change="()=>submitDocumentFile(index)"
                                         size="sm"></b-form-textarea>
                      </b-td>

                      <b-td class="text-center" style="width: 15%">
                        <b-link class="mr-2"><i class="icon fas fa-download  w-auto" @click="()=>onDownloadFile(file.id)"></i>
                        </b-link>
                        <b-link><i class="icon fas fa-eye  w-auto" @click="()=>onPreviewFile(file.id)"></i>
                        </b-link>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </perfect-scrollbar>
              </template>
              <template v-else style="text-align: center">
                <h4 style="color: darkred;font-weight: bold;text-align: center">NO DOCUMENTS FOUND</h4>
              </template>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-modal :id="infoModal.id" :title="msg('Confirmation')" @ok="archiveBankById(infoModal.propertyId)"
               ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetInfoModal">
        <p class="my-4">{{ msg('areyousureyouwanttotakethisbankout') }}</p>
      </b-modal>
      <!--New user popover-->
      <b-popover :target="`addBank`" placement="rightbottom" :title="msg('Add agency')">
        <template slot="title">
          {{ msg('Add agency') }}
        </template>
        <div class="md-list md-dense">
          <b-row class="mt-1">

            <b-col cols="12">

              <b-form-group :label="msg('Agency')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="newBank.bankId"
                    :options="allBanks"
                    class="w-100"
                    label="name"
                    :reduce="(bank) => bank.id"

                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="msg('Volume')+' (Bsp: 78000.60)'" label-for="building-company">
                <b-form-input id="realEstate-sell-price" number v-model="newBank.mortgage"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="msg('Monthly Payment')+' (Bsp: 550.5 )'" label-for="building-company">
                <b-form-input id="realEstate-sell-price" number v-model="newBank.monthlyPayment"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="msg('Commission') +' (Bsp: 3.4)'" label-for="building-company">
                <b-form-input id="realEstate-sell-price" number v-model="newBank.interest"/>
              </b-form-group>
            </b-col>


            <b-col cols="12">
              <b-form-group :label="msg('Credit Start')" label-for="rep-start">
                <calendar-picker v-model="newBank.creditStart" mode="date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="msg('Credit Ends')" label-for="rep-end">
                <calendar-picker v-model="newBank.creditEnd" mode="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--  actions-->
          <b-row>
            <b-col cols="6">
              <b-button variant="warning" @click="dismissPopover">
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">{{ msg('Cancel') }}</span>
              </b-button>
            </b-col>
            <b-col cols="6">

              <b-button variant="success" @click="save">
                <feather-icon icon="SaveIcon" class="mr-50"/>
                <span class="align-middle">{{ msg('Save') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-popover>
    </div>
    <div v-else-if="this.error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <b-spinner label="Spinning"/>
      {{ msg('loading') }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from "vue2-dropzone";

export default {
  name: 'bankdetails',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    historyMaxHeight: {
      default: 500,
      type: Number
    },
    emailAddress:null,
    disableAddBank: {
      required: false,
      type: Boolean
    }
  },
  watch: {},
  data: () => ({
    loaded: false,
    error: '',
    bankItem:null,
    currentActiveBankId: null,
    uploadFile: false,
    detailsRow: null,
    bankList: [],
    currentBankList: [],
    selectedBankFileIds:[],
    currentBankFileList: [],
    newBank: {
      mortgage: null,
      interest: null,
      monthlyPayment: null,
      name: null,
      creditStart: null,
      creditEnd: null,
    },
    infoModal: {
      id: 'info3-modal',
      title: '',
      content: ''
    },
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),

  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getUserFullName', 'getUsersByRole', 'getAllUsers', 'getUser']),
    ...mapGetters('building', ['fileTags']),
    editableFields() {
      return this.fields.filter(field => field.editable)
    },
    building: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    dropzoneOptions() {
      if (this.building == null || this.building.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'building/upload/file/' + this.building.id + '/' + this.currentActiveBankId,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 50000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",
      }
    },
    allBanks() {
      return this.bankList
          .map(bank => ({
            id: bank.id,
            name: (bank.name)
          }))
    },
    fields() {
      let $this = this
      return [

        {
          key: 'name',
          label: $this.msg('Name'),
          sortable: true
          , editable: false
        },
        {
          key: 'mortgage',
          label: $this.msg('volume'),
          sortable: true
          , editable: true

        },
        {
          key: 'monthlyPayment',
          label: $this.msg('Monthly payment'),
          sortable: false, editable: true

        },

        {
          key: 'interest',
          label: $this.msg('commission'),
          sortable: true, editable: true

        },

        {
          key: 'creditStart',
          label: $this.msg('Start'),
          sortable: true
        },
        {
          key: 'creditEnd',
          label: $this.msg('End'),
          sortable: true
        },
        {
          key: 'id',
          label: $this.msg('Action'),
          sortable: false
        },
      ]
    },

  },
  created() {
    let filterNew= {search: '', like: {name: "", shortDescription: ""}, eq: {type: "",buid:this.building.buid}, lte: {}, gte: {}}

        Promise.all([this._loadBankList({amount: 9999,filter:filterNew}).then(ans => {
      this.bankList = ans["Banks"];
      this.currentBankList = this.building.activeBanks;
    })])
        .finally(() => this.loaded = true)

  },
  beforeDestroy() {
    //  this.$root.$off('crm::clientId::bank::load', this.listeners.load)
  },
  methods: {
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['setBankToBuilding', 'updateBankDocumentFile']),
    ...mapActions('bank', {
      _loadBankList: 'getAllBanks'
    }),
    ...mapActions('building', ['sendChoosenFilesToMail']),
    sendAllFilesByMail(){

      this.sendChoosenFilesToMail({buildingId:this.building.id,email:this.emailAddress,filesId:this.selectedBankFileIds,isBank:true});

    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    archiveBankById(bankId) {
      let indexToRemove = this.building.activeBanksIds.findIndex(id => {
        return id === bankId;
      });

      this.building.activeBanksIds.splice(indexToRemove)

      let indexObjectToRemove = this.building.activeBanks.findIndex(obj => {
        return obj.bankId === bankId;
      });

      this.building.activeBanks.splice(indexObjectToRemove)

      this.$emit('delete')


    },
    onValueUpdated(args) {

      this.$emit('change', args)
    },
    onRowClicked(item) {
      this.bankItem=item;
      const {detailsRow} = this
      if (detailsRow && detailsRow !== item) {
        detailsRow._showDetails = false;
      }

      this.$set(item, "_showDetails", !item._showDetails);
      this.detailsRow = item;
      this.currentActiveBankId = item.bankId;
      this.currentBankFileList = item.files;
      this.emailAddress=null;
      this.selectedBankFileIds=[];
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    uploadComplete(file, response) {
      console.log('uploadComplete', {file, response})
      let responseData = JSON.parse(file.xhr.responseText);
      let building = responseData.objects["Building"];
      console.log('uploadComplete', building);
      this.building = building;
    //  this.onRowClicked(this.bankItem);

    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['Building'] == null)
          return;
        this.building = response.data['Building'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },
    doNothing(bankId) {
    },

    submitDocumentFile(index) {
      this.updateBankDocumentFile({
        id: this.building.id,
        bankId: this.currentActiveBankId,
        file: this.currentBankFileList[index]
      });
    },
    getFileSize(bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return '';
      }

      const units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return ' ('+bytes.toFixed(dp) + ' ' + units[u]+')';
    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },

    onDownloadFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    },
    onPreviewFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {

        let url=URL.createObjectURL(file.data);
        console.log("url is: ",url)
        window.open(url,'_blank')
      })
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onRowClick(order) {
      this.$emit("rowClicked", order)
    },
    reset() {
      this.loaded = false
      this.error = ''

      this.resetNewBank()

    },
    resetNewBank() {
      this.newBank = {
        mortgage: null,
        interest: null,
        monthlyPayment: null,
        name: null,
        creditStart: null,
        creditEnd: null,
      }
    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
    },

    loadBank(index) {

      let rep = this.allBanks[index]
      if (rep.id == this.newBank.id) {
        return
      }

      this.newBank = {...rep}
    },

    save() {
      let $this = this
      this.setBankToBuilding({id: this.building.id, newBank: this.newBank})
          .then(ans => {

                $this.building = ans;
                $this.currentBankList = ans.activeBanks;
              }
          )
          .finally(() => {
            $this.resetNewBank()
            $this.dismissPopover()
          })
    },

  }
}
</script>

<style scoped>

</style>
