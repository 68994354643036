export function BuildingInvoiceRequest(other) {
    other = other || {};
    return {
        ...other,
        buildId: other.buildId,
        invoiceTitle: other.invoiceTitle,
        items: (other.items || []).map(BuildingInvoiceItem),
        currency: other.currency,
    };
}

export function BuildingInvoiceItem(other) {
    other = other || {};
    return {
        ...other,
        externalId: other.externalId,
        name: other.name,
        description: other.description,
        price: other.price,
        quantity: other.quantity,
    };
}

export function BuildInvoiceRequestFromInvoice(invoice, invoiceDetails) {
    return {
        ...invoice,
        buildId: invoiceDetails.baseId,
        invoiceTitle: invoice.title,
        items: (invoiceDetails.items || []).map((item) => BuildingInvoiceItem(item)),
        currency: invoice.currency,
    };
}

