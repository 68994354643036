<template>

  <div>
    <b-button class="mb-1 mr-1" style="float: right" variant="primary" @click="generateInvoice" v-if="canCreateInvoice">
      {{ msg('Add Invoice') }}
    </b-button>
    <div>
      <b-table striped hover :items="invoices" :fields="fields" @row-clicked="openInvoice">
        <template v-slot:cell(actions)="data">
          <b-link v-if="data.item.invoiceStatus !== 'DRAFT'" class="mr-1" variant="danger" @click="downloadInvoice(data.item)">
            <i class="fa-solid fa-download"></i>
          </b-link>
          <b-link class="mr-1" v-if="data.item.invoiceStatus.toLocaleUpperCase() === 'OPEN'" variant="info" @click="payInvoice(data.item)">
            <i class="fa-solid fa-up-right-from-square"></i>
          </b-link>
        </template>
      </b-table>
    </div>
    <building-invoice-modal ref="building-invoice-modal" v-bind:building="value" @invoice="invoice => $emit('invoice', invoice)"/>
  </div>
</template>

<script>
import BuildingInvoiceModal from "@/components/modals/BuildingInvoiceModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "BuildingInvoiceTable",
  components: {BuildingInvoiceModal},
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('building', ['canCreateInvoice', 'canEditInvoice']),
    fields() {
      return [
        {key: 'invoiceId', label: this.msg('ID')},
        {key: 'title', label: this.msg('title')},
        {key: 'invoiceStatus', label: this.msg('status')},
        {key: 'totalAmount', label: this.msg('amount')},
        {key: 'actions', label: this.msg('Actions')},
      ];
    },
    invoices(){
      return this.value.buildingInvoices || []
    }
  },
  methods: {
    openInvoice(invoice) {
      if(this.canEditInvoice){
        this.$refs['building-invoice-modal'].showInvoice(invoice.invoiceId);
      }
    },
    generateInvoice() {
      this.$refs['building-invoice-modal'].showModal();
    },
    downloadInvoice(invoice) {
      //open link to download invoice
      let url = invoice.links.pdfLink
      window.open(url, '_blank');
    },
    payInvoice(invoice) {
      let url = invoice.links.paymentLink
      window.open(url, '_blank');
    },
  }
}
</script>


<style scoped>

</style>
