<template>
  <div>
    <div class="mb-1">
      <b-form-checkbox inline v-model="filter.fixed" switch @input="refresh"/>
      <label class="mx-3" style="font-size: 1rem">{{ $t('showFixed') }}</label>
      <b-form-checkbox inline v-model="filter.paid" switch @input="refresh"/>
      <label class="mx-3" style="font-size: 1rem">{{ $t('showPayed') }}</label>
    </div>
    <div>
      <b-table v-bind:fields="fields" v-bind:items="damages" v-bind:busy="loading" striped hover @row-clicked="onItemSelected">
        <template #cell(reportedTime)="data">
          <a :href="`/real_estate/contracts/rent/${data.item.contractId}`"  target="_blank">{{ data.value | moment('DD/MM/YY HH:mm') }}</a>
        </template>
        <template #cell(clientName)="data">
          <a :href="`/real_estate/contracts/rent/${data.item.contractId}`"  target="_blank">{{ data.value }}</a>
        </template>
        <template #cell(damageDescription)="data">
          {{ data.value }}
        </template>
        <template #cell(paid)="data">
          <b-badge v-if="data.value" variant="success">
            {{data.item.paidByClient ? $t("paidByClient") : $t("paid")}}
          </b-badge>
        </template>
        <template #cell(damageFixed)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">{{data.value ? msg("Yes") : msg("No")}}</b-badge>
        </template>
        <template #cell(reportedBy)="data">
          {{ data.value }}
        </template>
        <template #cell(damageCost)="data">
          {{ data.value }}
        </template>
      </b-table>

      <!--Table Paginator-->
      <div class="paginator text-center mt-1">
        <!--Paginator-->
        <div class="ml-2" style="display: inline-block ">
          <b-pagination
              :disabled="loading"
              limit="11"
              :per-page="pagination.size"
              :current-page="pagination.page"
              :total-rows="pagination.total"
              align="center"
              @input="onPaginationChange"
          />
        </div>
      </div>
    </div>
    <damage-modal ref="damageModal" @updated="refresh"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DamageModal from "@/components/modals/damage/damageModal.vue";

const getFilter = (buildingIds, realEstatesIds) => ({
  buildingIds: buildingIds,
  realEstatesIds: realEstatesIds,
  fixed: false,
  paid: false,
})

export default {
  name: "DamagePanel",
  components: {DamageModal},
  data: () => ({
    loading: true,
    filter: getFilter(),
    sort: {by: '', desc: false, direction: 'asc'},
    pagination: {amount: 10, page: 1, total: 0},
    damages: [],
  }),
  props: {
    buildingId: {
      type: String,
      required: false
    },
    realEstatesIds: {
      type: String,
      required: false
    }
  },
  watch: {
    buildingId: {
      handler() {
        this.refreshFilter()
      }
    },
    realEstatesIds: {
      handler() {
        this.refreshFilter()
      }
    }
  },
  created() {
    this.refreshFilter()
  },
  computed: {
    fields(){
      let $this = this;
      return [
        {
          key: 'reportedTime',
          label: $this.msg('time'),
          sortable: true
        },
        {
          key: 'clientName',
          label: $this.msg('client'),
          sortable: true
        },
        {
          key: 'damageDescription',
          label: $this.msg('Description'),
          sortable: true
        },
        {
          key: 'paid',
          label: $this.msg('paid'),
          sortable: true
        },
        {
          key: 'damageFixed',
          label: $this.msg('fixed'),
          sortable: true
        },
        {
          key: 'reportedBy',
          label: $this.msg('reportedBy'),
          sortable: true
        },
        {
          key: 'damageCost',
          label: $this.msg('Cost'),
          sortable: true
        },
      ]
    }
  },
  methods: {
    ...mapActions('damage', ['getAllDamage']),
    refreshFilter() {
      let buildingId = this.buildingId;
      let realEstatesIds = this.realEstatesIds;
      if (buildingId != null && !Array.isArray(buildingId)) {
        buildingId = [buildingId]
      }
      if (realEstatesIds != null && !Array.isArray(realEstatesIds)) {
        realEstatesIds = [realEstatesIds]
      }
      this.filter = {...this.filter, ...getFilter(buildingId, realEstatesIds)}
      this.pagination.page = 1
      this.refresh()
    },
    refresh() {
      let $this = this;
      if (this.buildingId == null && this.realEstatesIds == null) {
        this.damages = [];
        return;
      }
      this.loading = true;
      this.getAllDamage({
        ...$this.pagination, filter: $this.filter, sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(ans => {
        console.log("getAllDamage ans", ans);
        $this.damages = ans["Damages"];
      }, e => {
      }).finally(() => {
        $this.loading = false;
      });
    },
    onPaginationChange(page) {
      this.pagination.page = page;
      this.refresh();
    },
    onItemSelected(damage) {
      this.$refs.damageModal.showById(damage.id);
    },
  },
}
</script>


<style scoped>

</style>
