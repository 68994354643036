<template>

  <div>

    <b-button class="mx-1" :disabled="building.ownerId==null" style="float: right" variant="warning" @click="openPaymentModal">{{
        msg('Make payment')
      }}
    </b-button>
    <span class="ml-2">{{ msg('If button is blocked insert an owner first and save') }}</span>
    <div>
      <b-table striped hover :items="payments" :fields="fields" @row-clicked="openInvoice">
        <template #cell(paymentDate)="data">
          {{ formatDate(data.value) }}
        </template>

        <template #cell(amount)="data">
          {{ convertToEURO(data.value) }}
        </template>

        <template #cell(brand)="data">
          {{ (data.value !=null)?data.value:'' }} {{' **** '+data.item.digits}}
        </template>
      </b-table>
    </div>
    <b-modal no-fade v-model="dialogs.payment" size="l" ref="payment-modal"
             id="payment-modal" :hide-footer="true"
             :title="'Stripe Payment'"
             no-close-on-backdrop centered>
      <div class="mt-2 text-center">
        <b-form-input v-model="reason"
                      placeholder="Reason"
                      class="mb-2"></b-form-input>
        <b-form-input v-model="paymentAmount"
                      type="number"
                      placeholder="Amount"
                      step="0.01" min="0" max="3000"
                      class="mb-2"></b-form-input>
        <b-button :disabled="paymentAmount==null || paymentAmount<=0.0 || paymentLoading || reason==null"
                  variant="outline-success"
                  class="btn-dialog" @click="makePayment">
          {{ msg('Charge the owner now') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BuildingInvoiceModal from "@/components/modals/BuildingInvoiceModal.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BuildingInternalPaymentTable",
  components: {},
  data: () => ({
    dialogs: {payment: false},
    paymentAmount: 0.0,
    reason: null,
    paymentLoading: false,
  }),
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('building', ['canCreateInvoice', 'canEditInvoice']),
    fields() {
      return [
        {key: 'reason', label: 'Reason'},
        {key: 'amount', label: 'Amount'},
        {key: 'brand', label: 'Card'},
        {key: 'paymentDate', label: 'Date'}
      ];
    },
    building() {
      return this.value || {}
    },
    payments() {
      return this.value.ownerPayments || []
    }
  },
  methods: {
    ...mapActions("monetary", ["chargeOwner"]),
    formatDate(date) {
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Ensure 24-hour format
      };

      const formattedDate = new Date(date).toLocaleString('de-AT', options);
      return formattedDate.replace(',', ''); // Remove comma (optional)
    }
    ,
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    makePayment() {
      let $this = this;
      this.paymentLoading = true
      this.chargeOwner({
        ownerId: this.building.ownerId,
        buildingId: this.building.id,
        amount: this.paymentAmount,
        reason: this.reason
      }).then(approve => {
        $this.dialogs.payment = false
      }).finally(() => {
            $this.paymentLoading = false
            $this.paymentAmount = 0.0
            $this.reason = null
            $this.dialogs.payment = false
          }
      )

    },
    openPaymentModal() {

      this.dialogs.payment = true

    },
    openInvoice(invoice) {
      if (this.canEditInvoice) {
        this.$refs['building-invoice-modal'].showInvoice(invoice.invoiceId);
      }
    },
    generateInvoice() {
      this.$refs['building-invoice-modal'].showModal();
    },
    downloadInvoice(invoice) {
      //open link to download invoice
      let url = invoice.links.pdfLink
      window.open(url, '_blank');
    },
    payInvoice(invoice) {
      let url = invoice.links.paymentLink
      window.open(url, '_blank');
    },
  }
}
</script>


<style scoped>

</style>
