<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Company')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.company"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Address')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.address"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Zip')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.zip"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Location')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.location"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('GST')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.gst"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('KG')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.kg"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Bank')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.bank"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Usage')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.usage"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Total Space')" label-for="building-company">
          <b-form-input id="realEstate-sell-price" :readonly="readOnly" v-model="building.space"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


      <!-- Branch -->
      <b-col v-if="!readOnly" cols="12" md="6" lg="3">
        <b-form-group :label="msg('Branch')" label-for="realEstate-branch">
          <v-select

              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="building.buid"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"
          />
        </b-form-group>
      </b-col>

      <!-- Branch -->
      <b-col v-if="!readOnly" cols="12" md="6" lg="3">
        <b-form-group :label="msg('Contract template')" label-for="realEstate-branch">
          <v-select

              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="building.contractTemplateId"
              :options="allTemplates"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="!readOnly" cols="12" md="6" lg="3">
        <b-form-group :label="msg('Offer template')" label-for="realEstate-branch">
          <v-select
              multiple
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="building.offerTemplateIds"
              :options="allTemplates"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Owner')" label-for="realEstate-sell-price">
          <client-picker v-model="building.ownerId" is-owner :readOnly="readOnly" @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('City Tax')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="building.cityTax"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="msg('Vat') +' (Example 9.8)'" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" type="number" v-model="building.vat"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>


      <b-col cols="12"></b-col>

      <b-col v-if="!readOnly" cols="12" md="6">
        <b-form-group label-class="customColorLabel" :label="msg('Stripe Token')" >
          <b-form-input  :readonly="readOnly" v-model="building.stripeApiKey"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col v-if="!readOnly" cols="12" md="6" >
        <b-form-group label-class="customColorLabel" :label="msg('Stripe Public Token')" >
          <b-form-input :readonly="readOnly" v-model="building.stripePublicApiKey"
                        @change="onValueUpdated"/>
        </b-form-group>
      </b-col>

      <b-col cols="12"></b-col>
      <b-col cols="12" md="6">
        <b-form-group :label="msg('Hostaway ID')" >

          <b-form-input v-model="building.hostawayId"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group :label="msg('Hostaway SECRET')" >
          <b-form-input v-model="building.hostawaySecret"/>
        </b-form-group>
      </b-col>
      <b-col cols="12"></b-col>
      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="msg('Copy Hostaway Credentials from other building')" label-for="product-branch">
          <v-select
              placeholder="Copy from Building"
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="buildingHostaway"
              :options="buildings"
              class="w-100"
              label="address"
              :reduce="(building) => building"
              @option:selected="copyCredentials()"

          />
        </b-form-group>
      </b-col>

      <b-col v-if="!readOnly" cols="12" md="6" lg="6">
        <b-form-group :label="msg('Hostaway reservations')" label-for="realEstate-sell-price">
          <b-button :disabled="hostawayLoading" @click="fetchHostawayReservations">
            <b-spinner small v-if="hostawayLoading" class="mr-2"></b-spinner>
            Fetch Hostaway reservations
          </b-button>
        </b-form-group>
      </b-col>



    </b-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from "vue2-dropzone";
import ClientPicker from "@/components/components/picker/clientPicker.vue";

const buildingFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})
export default {
  name: 'buildingManagement',
  components: {
    ClientPicker,
    // eslint-disable-next-line vue/no-unused-components
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      templateList: [],
      file: null,
      buildingFilter: buildingFilter(),
      buildings: [],
      buildingHostaway: null,
      hostawayLoading: false,

    }

  },
  watch: {

    file: {
      handler() {
        this.uploadFile()
      }
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },

  },
  created() {
    let $this = this;
    let filterNew = {
      search: '',
      like: {name: "", shortDescription: ""},
      eq: {type: "", buid: this.building.buid, template: true},
      lte: {},
      gte: {}
    }
    Promise.all([this._loadTemplateList({amount: 9999, filter: filterNew})
        .then(ans => {
          $this.templateList = ans["Templates"];

        }), this.getAllBuildings({
      page: 1,
      amount: 9999,
      filter: $this.buildingFilter,
      sortcolumn: 'updatedDate',
      sortby: 'desc',
    }).then($this.onBuildingsRetrieve, (error) => $this.error = error)])
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('building', ['fileTags', 'allStatuse', 'canOperateBuilding']),
    ...mapGetters('building', ['allBuildingUsers', 'canUploadExpose']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    allBuildingUsersPerm() {
      return this.allBuildingUsers
    },
    allUsrs() {
      return this.getAllUsers
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allTemplates() {
      return this.templateList
          .map(bank => ({
            id: bank.id,
            name: (bank.name)
          }))
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    dropzoneOptions() {
      if (this.building == null || this.building.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'building/upload/file/' + this.building.id + '?tag=Expose',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",
      }
    },
    building: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions('contractTemplate', {_loadTemplateList: 'getAllTemplates'}),
    ...mapActions('building', ['requestExposeMail']),
    ...mapActions('building', ['updateDocumentFile']),
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['fetchHostawayContracts']),
    ...mapActions('building', ['getAllBuildings']),

    fetchHostawayReservations() {
      this.hostawayLoading = true; // Start loading
      this.fetchHostawayContracts(this.building.id)
          .then(() => {
            // Handle success if needed
          })
          .catch(error => {
            // Handle error if needed
            console.error("An error occurred:", error);
          })
          .finally(() => {
            this.hostawayLoading = false; // Stop loading on either success or error
          });
    },
    getFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return '';
      }

      const units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return ' (' + bytes.toFixed(dp) + ' ' + units[u] + ')';
    },
    submitDocumentFile(index) {
      this.updateDocumentFile({
        id: this.building.id,
        file: this.building.files[index]
      });
    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },

    uploadComplete(file, response) {
      let responseData = JSON.parse(file.xhr.responseText);
      let building = responseData.objects["Building"];
      this.building = building;
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['Building'] == null)
          return;
        this.building = response.data['Building'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },
    onDownloadFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    },
    onPreviewFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {

        let url = URL.createObjectURL(file.data);
        console.log("url is: ", url)
        window.open(url, '_blank')
      })
    },
    uploadFile() {
      let $this = this
      console.log('uploadExcel')
      if (this.file == null)
        return;

      let paramMap = {"buid": this.buid}
      this._uploadExcel({file: this.file, params: paramMap}).finally(() => $this.refresh());

    },
    requestExpose(type) {
      let $this = this;
      this.requestExposeMail({buildingId: this.building.id, type: type}).then(ans => {
        if (type === 'Expose')
          $this.building.exposeRequested = true;

        if (type === 'Liquidity')
          $this.building.liquidityRequested = true;

        if (type === 'Opinion')
          $this.building.opinionRequested = true;

      })
    },
    copyCredentials() {
      console.log("copyCredentials", this.buildingHostaway)
      this.building.hostawayId = this.buildingHostaway.hostawayId
      this.building.hostawaySecret = this.buildingHostaway.hostawaySecret
    }
    , onBuildingsRetrieve(data) {
      this.buildings = data["Buildings"].filter(building => building.hostawayId != null && building.hostawaySecret != null);
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}

.customColorLabel {
  color: rgba(106, 119, 204, 0.66) !important;
  font-weight: bold;
  text-shadow: #0c141c !important;
}
</style>
