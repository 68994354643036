<template>
  <div>
    <b-modal ref="invoiceModal" size="lg" title="Invoice" centered hide-footer content-class="p-0" body-class="p-0">
      <div>
        <b-progress :variant="progress.variant" :value="progress.value" :max="progress.max" style="border-radius: 0"></b-progress>
        <div class="p-1">
          <div v-show="step === STEPS.SET_EXPENSES">
            <b-form-group :label="msg('Expense')">
              <b-form-select v-model="expenseId" :options="expensesOptions"/>
            </b-form-group>
            <b-form-group :label="msg('Tax')">
              <b-form-select v-model="selectedTax" :options="taxesOptions"/>
            </b-form-group>
            <b-button block class="mb-1" variant="primary" @click="setItemsByExpenses">
              {{ msg('Continue') }}
            </b-button>
          </div>
          <div v-show="step === STEPS.SET_ITEMS">
            <b-form-group :label="msg('Title')">
              <b-form-input v-model="invoice.invoiceTitle"/>
            </b-form-group>
          </div>
          <div v-show="step === STEPS.SET_ITEMS">
            <hr/>
            <h5>{{ msg('Items') }}</h5>
            <draggable v-model="invoice.items" group="people" @start="drag=true" @end="drag=false">
              <div class="mb-1" v-for="item in invoice.items" :key="item.id">
                <b-row class="m-0">
                  <b-col class="pl-0" cols="7">
                    <b-form-input v-model="item.name"/>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input v-model="item.quantity"/>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input v-model="item.price"/>
                  </b-col>
                  <b-col class="pr-0" cols="1">
                    <b-button block class="px-0" variant="outline-danger"
                              @click="invoice.items.splice(invoice.items.indexOf(item), 1)">
                      <i class="fa fa-trash"/>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </draggable>
            <div class="text-center">
              <b-button pill variant="outline-info" @click="addItem">
                <i class="fa-solid fa-plus"></i>
              </b-button>
            </div>
            <hr/>
            <b-button block class="mb-1" variant="primary" @click="generateInvoice" v-bind:disabled="loading.action">
              <b-spinner small type="grow" v-if="loading.action"></b-spinner>
              {{ invoiceId == null ?  msg('Add Invoice') : msg('Update Invoice') }}
            </b-button>
          </div>
          <div v-show="step === STEPS.CONFIRM">
            <b-link v-if="isDraft" @click="step = STEPS.SET_ITEMS">
              <i class="fa-solid fa-arrow-left"></i>
            </b-link>
            <div  v-if="invoiceDetails != null">
              <h3 class="text-center">{{ invoice.invoiceTitle }}</h3>
              <b-table-simple >
                <b-thead>
                  <b-tr>
                    <b-th>{{ msg('Item') }}</b-th>
                    <b-th>{{ msg('Quantity') }}</b-th>
                    <b-th>{{ msg('Price') }}</b-th>
                    <b-th>{{ msg('Total') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in invoiceDetails.items" :key="item.id">
                    <b-td>{{ item.name }}</b-td>
                    <b-td>{{ item.quantity }}</b-td>
                    <b-td>{{ item.price }}</b-td>
                    <b-td>{{ item.total }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="text-right mb-2">
                <span style="font-size: 12px">{{ msg('Tax') }}: {{ invoiceDetails.taxAmount | numberFormatter}} €</span>
                <br/>
                <b-badge style="margin-top: 5px" variant="primary">{{ msg('Total') }}: {{ invoiceDetails.amount | numberFormatter}} €</b-badge>
              </div>
            </div>
            <b-row>
              <b-col :cols="isDraft ? 6 : 12">
                <b-button block class="mb-1" variant="danger" @click="tryDeleteInvoice">
                  {{ msg('Delete') }}
                </b-button>
              </b-col>
              <b-col v-if="isDraft" cols="6">
                <b-button block class="mb-1" variant="success" @click="sendInvoice" :disabled="loading.action">
                  {{ msg('Send') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-show="step === STEPS.COMPLETED">
            <div v-if="invoiceDetails != null">
              <h3 class="text-center">{{ invoice.invoiceTitle }}</h3>
              <b-table-simple >
                <b-thead>
                  <b-tr>
                    <b-th>{{ msg('Item') }}</b-th>
                    <b-th>{{ msg('Quantity') }}</b-th>
                    <b-th>{{ msg('Price') }}</b-th>
                    <b-th>{{ msg('Total') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in invoiceDetails.items" :key="item.id">
                    <b-td>{{ item.name }}</b-td>
                    <b-td>{{ item.quantity }}</b-td>
                    <b-td>{{ item.price }}</b-td>
                    <b-td>{{ item.total }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="text-right mb-2">
                <span style="font-size: 12px">{{ msg('Tax') }}: {{ invoiceDetails.taxAmount | numberFormatter}} €</span>
                <br/>
                <b-badge variant="primary">{{ msg('Total') }}: {{ invoiceDetails.amount | numberFormatter}} €</b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {BuildingInvoiceRequest, BuildingInvoiceItem, BuildInvoiceRequestFromInvoice} from '@/model/RealEstate/invoice/BuildInvoiceRequest'
import draggable from 'vuedraggable'
import {BuildingExpensesModel} from '@/model/RealEstate/BuildingModel'
import {mapActions} from "vuex";

const STEPS = {"SET_EXPENSES": 0, "SET_ITEMS": 1, "CONFIRM": 2, "COMPLETED": 3};

export default {
  name: "BuildingInvoiceModal",
  components: {draggable},
  props: {
    building: {
      type: Object,
    }
  },
  data: () => ({
    STEPS,
    loading: {
      invoice: false,
      action: false,
    },
    taxes: [],
    drag: false,
    step: 1,
    expenseId: null,
    selectedTax: null,
    invoiceId: null,
    invoiceStatus: null,
    invoiceDetails: null,
    invoice: BuildingInvoiceRequest(),
  }),
  created() {
    let $this = this;
    $this.getTaxes().then(taxes => {
      $this.taxes = taxes;
    });
  },
  computed: {
    expensesOptions() {
      return this.building.buildingExpenses.map(expense => {
        return {value: expense.id, text: `${expense.month}/${expense.year}`}
      });
    },
    taxesOptions() {
      return [ {value: null, text: ""}, ...(this.taxes || []).map(tax => {
        return {value: tax.id, text: `${tax.displayName} - ${tax.description} - ${tax.country} - ${tax.rate} %`}
      })];
    },
    progress() {
      let variant = "primary";
      if (this.invoiceStatus === "PAID") {
        variant = "success";
      } else if (this.invoiceStatus === "CANCELED" || this.invoiceStatus === "VOIDED") {
        variant = "danger";
      }
      return {value: this.step, max: 3, variant};
    },
    isDraft() {
      return this.invoiceStatus == null || this.invoiceStatus === "DRAFT";
    }
  },
  methods: {
    ...mapActions('building', ['retrieveInvoice', 'createDraftInvoice', 'updateDraftInvoice', 'finalizeDraftInvoice', 'deleteInvoice']),
    ...mapActions('tax', ['getTaxes']),
    showModal() {
      this.step = STEPS.SET_EXPENSES;
      this.invoiceDetails = null;
      this.invoiceId = null;
      this.invoiceStatus = null;
      this.invoice = BuildingInvoiceRequest({buildId: this.building.id});
      this.$refs.invoiceModal.show();
    },
    showInvoice(invoiceId) {
      let $this = this;
      this.loading.invoice = true;
      this.$refs.invoiceModal.show();
      this.invoiceDetails = null;
      this.retrieveInvoice(invoiceId).then(({invoice,invoiceDetails}) => {
        $this.invoice = BuildInvoiceRequestFromInvoice(invoice, invoiceDetails);
        $this.invoiceDetails = invoiceDetails;
        $this.invoiceId = invoiceId;
        $this.invoiceStatus = invoice.invoiceStatus;
        $this.step = invoice.invoiceStatus === "DRAFT" ? STEPS.SET_ITEMS : STEPS.CONFIRM;
        if(invoice.invoiceStatus === "PAID" || invoice.invoiceStatus === "CANCELED" || invoice.invoiceStatus === "VOIDED"){
          $this.step = STEPS.COMPLETED;
        }
      }).finally(() => {
        $this.loading.invoice = false;
      });
    },
    setItemsByExpenses() {
      let $this = this;
      let selectedExpense = (this.building.buildingExpenses || []).find(es => es.id === $this.expenseId);
      if(selectedExpense != null) {
        this.generateItems(selectedExpense, this.invoice.items);
        this.invoice.invoiceTitle = `Invoice ${selectedExpense.month}/${selectedExpense.year}`;
      }
      this.step = STEPS.SET_ITEMS;
    },
    generateItems(expenses, items){
      let selectedExpense = BuildingExpensesModel((expenses || {}));
      Object.keys(selectedExpense).forEach(key => {
        if(key === 'id' || key === 'month' || key === 'year'){
          return
        }
        items.push(BuildingInvoiceItem({name: key, quantity: 1, price: Math.abs(selectedExpense[key])}));
      });
    },
    addItem() {
      if( this.invoice.items.some(item => item.name === '')){
        return;
      }
      this.invoice.items.push(BuildingInvoiceItem({name: '', quantity: 1, price: 0}));
    },
    generateInvoice() {
      let $this = this;
      this.loading.action = true;
      let request = this.invoiceId == null ? this.createDraftInvoice({...this.invoice, taxRateIds: this.selectedTax != null ? [this.selectedTax] : []}) : this.updateDraftInvoice({id: this.invoiceId, invoice: this.invoice});
      request.then(({invoice,invoiceDetails}) => {
        $this.invoiceId = invoiceDetails.id;
        $this.invoiceDetails = invoiceDetails;
        $this.invoice = BuildInvoiceRequestFromInvoice(invoice, invoiceDetails);
        $this.invoiceStatus = invoice.invoiceStatus;
        $this.step = STEPS.CONFIRM;
        $this.$emit('invoice', invoice);
      }).finally(() => {
        $this.loading.action = false;
      });
    },
    sendInvoice() {
      this.loading.action = true;
      let $this = this;
      this.finalizeDraftInvoice(this.invoiceId).then(({invoice,invoiceDetails}) => {
        $this.$emit('invoice', invoice);
        this.$refs.invoiceModal.hide();
      }).finally(() => {
        this.loading.action = false;
      });
    },
    tryDeleteInvoice(){
      this.loading.action = true;
      let $this = this;
      this.deleteInvoice(this.invoiceId).then(() => {
        $this.$emit('invoice', null);
        this.$refs.invoiceModal.hide();
      }).finally(() => {
        this.loading.action = false;
      });
    }
  }
}
</script>


<style scoped>

</style>
