<template>
  <div v-if="loaded">
    <todo-list :value="value" todo-building></todo-list>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {date} from "@/global/helper";
import TodoList from "@/components/widgets/ToDoItem";

export default {
  name: 'buildingTodo',
  components: {TodoList},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    nukiList: [],
    emailAddress: ""
  }),
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    allNukis() {
      return this.nukiList
          .filter(lock => lock.propertyId === null || lock.propertyId === this.realEstate.id)
          .map(lock => ({
            id: lock.id,
            name: (lock.name)
          }))
    },
  },
  created() {
     this.loaded = true;

  },
  methods: {
    ...mapActions('nuki', {_loadNukiList: 'getNukiList', _sendMail: 'sendMail'}),

    onValueUpdated(args) {
      this.$emit('change', args)
    },
    sendEmail() {
      let $this = this;
      if (this.emailAddress != null) {
        this._sendMail({emailAddress: $this.emailAddress, propertyId: this.realEstate.id})
      }
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
