<template>
  <div>
    <b-row class="mt-1 mb-1" v-for="(text, index) in building.buildingTexts" :key="index">

      <b-col cols="6">
        <b-form-group label-size="xl">
          <b-form-input size="sm" v-model="text.title" :placeholder="msg('Enter title')"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">

        <b-form-group label-size="xl">
          <validation-provider #default="{ errors }" :name="msg('Real estate description')">
            <quill-editor
                spellcheck="false"
                style="background-color: rgba(42,54,82,0.32)"
                class="building-description"
                v-bind:value="text.description"
                @change="onEditorChange(index, $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
<!--     <b-col cols="12">

       <div class="mt-2"  :style="{ border: '1px solid ' }">
         &lt;!&ndash; Your content goes here &ndash;&gt;
       </div>

     </b-col>-->
    </b-row>
    <b-button @click="addNewDescription" variant="primary" class="mt-1">Add New Description</b-button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'buildingText',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buildingTexts: [],
    };
  },
  created() {
    if (!this.building.buildingTexts) {
      this.$set(this.building, 'buildingTexts', []);
    } else {
      this.buildingTexts = this.building.buildingTexts.map(text => ({...text}));
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),

    allBranches() {
      return Object.values(this.getAllBranches);
    },
    allCountries() {
      return Object.values(this.getAllCountries);
    },
    building: {
      get() {
        return this.value;
      },
      set(val) {
        // Set the updated buildingTexts array back to the building object
        val.buildingTexts = this.buildingTexts;
        console.log("-------------- building", val)
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onEditorChange(index, {quill, html, text}) {
      if (this.building.buildingTexts[index].description === html) return;
      this.$set(this.building.buildingTexts[index], 'description', html);
      this.onValueUpdated({quill, html, text});
    },
    onValueUpdated(args) {
      this.$emit('change', args);
    },
    addNewDescription() {
      this.building.buildingTexts.push({title: '', description: ''});
    },
  },
};
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
