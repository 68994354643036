export function BuildingModel(other) {
    other = other || {};
    return {
        ...other,
        id: other.id,
        orgid: other.orgid,
        status: other.status,
        histories: other.histories,
        company: other.company,
        address: other.address,
        gst: other.gst,
        kg: other.kg,
        zip: other.zip,
        location: other.location,
        usage: other.usage,
        space: other.space,
        qmIst: other.qmIst,
        rentPa: other.rentPa,
        qmPotential: other.qmPotential,
        bank: other.bank,
        open: other.open,
        buyPrice: other.buyPrice,
        interest: other.interest,
        yearlyCost: other.yearlyCost,
        creditEnd: other.creditEnd,
        lien: other.lien,
        appraisalWorth: other.appraisalWorth,
        sellWorthIntern: other.sellWorthIntern,
        bookWorth: other.bookWorth,
        buid: other.buid,
        propertyIds: other.propertyIds,
        attachments: other.attachments,
        clientIds: other.clientIds,
        contractTemplateId: other.contractTemplateId,
        offerTemplateIds: other.offerTemplateIds,
        activeBanks: other.activeBanks,
        activeBanksIds: other.activeBanksIds,
        files: other.files,
        refinanceId: other.refinanceId,
        isRefinanced: other.isRefinanced,
        exposeRequested: other.exposeRequested,
        exposeUploaded: other.exposeUploaded,
        liquidityRequested: other.liquidityRequested,
        liquidityUploaded: other.liquidityUploaded,
        opinionRequested: other.opinionRequested,
        opinionUploaded: other.opinionUploaded,
        buildingExpenses: (other.buildingExpenses || []).map(BuildingExpensesModel),
        buildingInvoices: (other.buildingInvoices || []).map(BuildingInvoiceModel),
        userIds: other.userIds,
        ownerId: other.ownerId,
        updatedDate: other.updatedDate,
        transactionId: other.transactionId,
    }
}

export function BuildingExpensesModel(other) {
    other = other || {};
    return {
        ...other,
        id: other.id,
        rent: other.rent,
        internet: other.internet,
        insurance: other.insurance,
        damage: other.damage,
        gas: other.gas,
        electricity: other.electricity,
        cleaning: other.cleaning,
        commission: other.commission,
        management: other.management,
        month: other.month,
        year: other.year,
    }
}

export function BuildingInvoiceModel(other) {
    other = other || {};
    return {
        ...other,
        invoiceId: other.invoiceId,
        creationDate: other.creationDate,
        lastUpdatedDate: other.lastUpdatedDate,
        invoiceStatus: other.invoiceStatus,
        totalAmount: other.totalAmount,
    }
}
