<template>
  <div>
    <b-row class="mt-1">

      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="6" lg="4">

        <b-form-group :label="msg('House manager')" label-for="realEstate-branch">
          <v-select
              multiple

              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              v-model="houseManagers"
              :options="allHouseManager"
              class="w-100"
              label="name"
              :reduce="(usr) => usr.id"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="6" lg="4">

        <b-form-group :label="msg('Architect')" label-for="realEstate-branch">
          <v-select
              multiple
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              v-model="architects"
              :options="allArchitects"
              class="w-100"
              label="name"
              :reduce="(usr) => usr.id"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="6" lg="4">

        <b-form-group :label="msg('Bank agent')" label-for="realEstate-branch">
          <v-select
              multiple

              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              v-model="agents"
              :options="allBankAgents"
              class="w-100"
              label="name"
              :reduce="(usr) => usr.id"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="row">
        <div class="col">
          <hr>
        </div>
        <div class="col-auto">ODER</div>
        <div class="col">
          <hr>
        </div>
      </b-col>


      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="8" lg="8">
        <b-form-group :label="msg('Pick an user to send him an invite email to the building')"
                      label-for="realEstate-branch">

          <b-input-group>

            <v-select
                multiple

                :dir="isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                v-model="inviteNewUserIds"
                :options="allUsrs"
                class="w-100"
                label="name"
                :reduce="(usr) => usr.id"
            />

          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="4" lg="4">
        <b-form-group :label="msg('Send mail')" label-for="realEstate-branch">

          <b-input-group>
            <b-button @click="sendUserEmail" :disabled="inviteNewUserIds==null||inviteNewUserIds.length<1"
                      variant="outline-primary" v-b-tooltip :title="msg('Send Email to user')">
              {{msg('SEND INVITATION EMAIL').toUpperCase()}}
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="row">
        <div class="col">
          <hr>
        </div>
        <div class="col-auto">ODER</div>
        <div class="col">
          <hr>
        </div>
      </b-col>


      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="8" lg="8">
        <b-form-group :label="msg('Pick an bank agent to share the refinance details about this property')"
                      label-for="realEstate-branch">

          <b-input-group>

            <v-select
                multiple
                :dir="isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                v-model="refinanceAgents"
                :options="allBankAgents"
                class="w-100"
                label="name"
                :reduce="(usr) => usr.id"

            />

          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col v-if="allBuildingUsersPerm &&!readOnly" cols="12" md="4" lg="4">
        <b-form-group :label="msg('Send mail')" label-for="realEstate-branch">

          <b-input-group>
            <b-button @click="sendAgentEmail" :disabled="building.refinanceId==null|| refinanceAgents==null||refinanceAgents.length<1"
                      variant="outline-primary" v-b-tooltip :title="msg('Send Email to user')">
              {{building.refinanceId==null?msg("Please refinance this building first"):msg("SEND INVITATION EMAIL").toUpperCase()}}
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

const updateBuildingUsers = function (type,val,$this){
  if ($this.building.userIds==null)
    $this.building.userIds=[];

  console.log(type + " set", val);
  let current = [...$this.building.userIds];

  let removed = $this[type].filter(user => !val.includes(user.id)).map(user=>user.id)
  current = current.filter(userId => !removed.includes(userId))
  // this.building.userIds.push(val[0])
  $this.building.userIds = [...val, ...current]
  $this.$emit('input', $this.building)
}

export default {
  name: 'buildingUserManagement',
  components: {},
  data() {
    return {

      inviteNewUserIds: null,
      refinanceAgents: null
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },

  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('building', ['allBuildingUsers']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    allBuildingUsersPerm() {
      return this.allBuildingUsers
    },

    allUsrs() {
      return this.getAllUsers
          .filter(us=>us.email!=null && us.email!=='' && us.roleId !== 22&& us.roleId !== 23&& us.roleId !== 24&& us.roleId !== 25)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allBankAgents() {
      return this.getAllUsers
          .filter(us => us.roleId === 23)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allArchitects() {
      return this.getAllUsers
          .filter(us => us.roleId === 24)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allHouseManager() {
      return this.getAllUsers
          .filter(us => us.roleId === 22)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    building: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    agents: {
      get() {
        if (this.value.userIds==null)return [];
        return this.allBankAgents.filter(us => this.value.userIds.includes(us.id))

      },
      set(val) {
        updateBuildingUsers("agents",val,this)
      }
    },

    architects: {
      get() {
        if (this.value.userIds==null)return [];
        return this.allArchitects.filter(us => this.value.userIds.includes(us.id))

      },
      set(val) {
        updateBuildingUsers("architects",val,this)

      }
    },
    houseManagers: {
      get() {
        if (this.value.userIds==null)return [];
        return this.allHouseManager.filter(us => this.value.userIds.includes(us.id))

      },
      set(val) {
        updateBuildingUsers("houseManagers",val,this)
        /*if (this.building.userIds==null)
          this.building.userIds=[];

        console.log("houseManagers set", val);
        let current = [...this.building.userIds];

        let removed = this.houseManagers.filter(user => !val.includes(user.id)).map(user=>user.id)
        current = current.filter(userId => !removed.includes(userId))
        // this.building.userIds.push(val[0])
        this.building.userIds = [...val, ...current]
        this.$emit('input', this.building)*/
      }
    },


  },
  methods: {
    ...mapActions('building', ['sendUserInviteMail', 'sendBankAgentMail']),

    sendUserEmail() {
      let $this = this;
      if (this.inviteNewUserIds != null) {
        this.sendUserInviteMail({userIds: this.inviteNewUserIds, buildingId: this.building.id})
            .then((ans) => {
              $this.inviteNewUserIds = null
              $this.building.userIds.push.apply($this.building.userIds, ans);

            })
      }
    },
    sendAgentEmail() {
      let $this = this;
      if (this.refinanceAgents != null) {
        this.sendBankAgentMail({userIds: this.refinanceAgents, buildingId: this.building.id})
            .then((ans) =>{

              $this.refinanceAgents = null
              $this.building.userIds.push.apply($this.building.userIds, ans);

            } )

      }
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
